import Machine from './lib/Machine.js';

export default new Machine(
    {
        IDLE: {
            on: {
                blip: 'VALIDATING',
            },
        },
        VALIDATING: {
            on: {
                accept: 'DONE',
                connect: 'CONNECT',
                reject: 'ERROR',
            },
        },
        CONNECT: {
            on: {
                login: 'CONNECTED',
                unknownUser: 'REGISTRATION',
                back: 'IDLE',
            },
        },
        REGISTRATION: {
            on: {
                register: 'REGISTERED',
                back: 'CONNECT',
            },
        },
        CONNECTED: {
            final: true,
        },
        REGISTERED: {
            final: true,
        },
        DONE: {
            final: true,
        },
        ERROR: {
            on: {
                back: 'IDLE',
            },
        },
    },
    {
        city: 'malmo',
        cardnumber: '',
        email: '',

        setCity(city) {
            this.city = city;
        },

        hasValidCardNumber() {
            return this.cardnumber.length > 5 && this.cardnumber.length <= 10;
        },

        enterCardNumber(number) {
            if (this.cardnumber.length < 10) {
                this.cardnumber += number;
            }
        },
    },
);
